import "../reset.scss";
import "./styles.scss";
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/effect-fade';

import Swiper from 'swiper';
import { Navigation, Pagination, EffectFade } from 'swiper/modules'

const cardsForDrivers = () => {
  new Swiper('.cards-services-for-drivers-slider .swiper', {
    modules: [Navigation, Pagination, EffectFade],
    spaceBetween: 40,

    loop: false,
    pagination: {
      el: ".cards-services-for-drivers-slider .swiper .swiper-pagination",
      type: 'bullets',
      clickable: true,
    },

    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev',
    },

    breakpoints: {
      1300: {
        slidesPerView: 3,
      },

      1024: {
        slidesPerView: 2,
      },

      768: {
        slidesPerView: 1,
        spaceBetween: 20,
      },

      425: {
        slidesPerView: 1,
        spaceBetween: 15,
      },

      375: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
    },
  });
}

cardsForDrivers();