import '../reset.scss'
import './styles.scss'

const phoneInput = document.querySelector('[data-js="input"]')

phoneInput?.addEventListener('input', handleInput, false)

function handleInput (e:any) {
  e.target.value = phoneMask(e.target.value)
}

function phoneMask (phone:any) {
  return phone.replace(/\D/g, '')
    .replace(/^(\d)/, '($1')
    .replace(/^(\(\d{2})(\d)/, '$1) $2')
    .replace(/(\d{4,5})(\d{4})/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1');
}