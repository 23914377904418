import "../reset.scss";
import "./styles.scss";
import "swiper/scss";
import "swiper/scss/navigation";

import Swiper from "swiper";
import { Navigation } from "swiper/modules";

const innerWebdoorSwiper = () => {
  new Swiper(".slider-inner-webdoor .swiper", {
    modules: [Navigation],
    spaceBetween: 6,
    breakpoints: {
      1024: {
        slidesPerView: 3,
      },

      768: {
        slidesPerView: 2,
        spaceBetween: 0,
      },

      640: {
        slidesPerView: 2,
      },

      375: {
        slidesPerView: 3,
        spaceBetween: 0,
      },
    },

    navigation: {
      nextEl: ".swiper-next",
      prevEl: ".swiper-prev",
    },
  });
};

innerWebdoorSwiper();
