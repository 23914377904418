import "../../components/reset.scss";
import "./styles.scss";

import { getSiblings, isOverflown } from "../../helpers/main";

let MenuSwipe = (element: string, elementChild: string, navMode?: boolean) => {
  const components = Array.from(document.querySelectorAll(element));
  let isMobile = window.matchMedia("(max-width: 1024px)").matches;
  let scrollLeft: number;
  let isDown = false; // indica se o swipe está ativo.

  components.forEach((componentItem) => {
    let isOverflowing = isOverflown(componentItem);

    /**
     * Quando a função roda novamente, os event listeners são adicionados mais uma vez..
     * Ou seja, otimizar: se a função de novo, remover o eventListener anterior, antes de aplicar o mais recente.
     */
    if (componentItem) {
      const slider = componentItem.querySelector(elementChild) as HTMLElement;

      if (isOverflowing === true) {
        /**
         * Se o overflow do componente é false, então aplica uma classe que centraliza os itens.
         * Se for true, remover.
         **/
        if (componentItem.classList.contains("center")) {
          componentItem.classList.remove("center");
        }

        if (!isMobile) {
          let startX: number = 0; // indica onde ocorreu o click inicial.
          let wasTheMenuMovedBefore = false;
          let oldPosition = 0;

          componentItem.addEventListener("mousedown", (e) => {
            isDown = true;
            slider.classList.add("active");

            /**
             * pageX retorna as coordenadas do lugar onde você clicou.
             * offsetLeft obtém a margin do elemento; tal valor é subtraído do pageX, pois ele afeta o valor do mesmo.
             */
            startX = (e as MouseEvent).pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
          });

          componentItem.addEventListener("mouseleave", () => {
            isDown = false;
            slider.classList.remove("active");
          });

          componentItem.addEventListener("mouseup", () => {
            isDown = false;
            slider.classList.remove("active");

            wasTheMenuMovedBefore = !wasTheMenuMovedBefore; // ao remover o mouse da div armazenando o menu, a variável é invertida; false vira true e vice-versa.
            oldPosition = componentItem.scrollLeft; // então, é verificado em mousemove.
          });

          componentItem.addEventListener("mousemove", (e) => {
            if (!isDown) { // Evita execuções desnecessárias.
              return;
            }
            
            e.preventDefault(); // impede a seleção de texto.

            let pageX = (e as MouseEvent).pageX; // pageX é a coordenada horizontal do ponteiro do mouse, quando o evento é disparado; o documento é usado como o ponto de referência.
            let sliderOffset = slider.offsetLeft; // offsetLeft retorna o espaço à esquerda do componente, em relação ao pai; ele considera margin, padding, scroll e a propriedade 'left', usada com position: absolute.
            let x = pageX - sliderOffset; // x é a diferença entre o local onde o evento é disparado *e* do espaço à esquerda no componente.
            let distanceMoved = x - startX; // distância movida.
            let positionAfterSwipe = scrollLeft - distanceMoved; // Posição após o swipe.

            wasTheMenuMovedBefore ? (componentItem.scrollLeft = oldPosition + positionAfterSwipe) : (componentItem.scrollLeft = positionAfterSwipe); // A propriedade 'scrollLeft' do 'componentItem' é alterada, para a posição desejada (que depende do movimento do mouse).
          });
        }

        else {
          let touchPositionX: number = 0;
          let wasTheMenuMovedBefore = false;
          let oldPosition = 0;

          componentItem.addEventListener("touchstart", (e) => {
            let clientX = (e as TouchEvent).touches[0].clientX;
            let sliderOffset = slider.offsetLeft;

            isDown = true;
            slider.classList.add("active");

            touchPositionX = clientX - sliderOffset;
            scrollLeft = slider.scrollLeft;
          });

          componentItem.addEventListener("touchend", () => {
            isDown = false;
            slider.classList.remove("active");

            wasTheMenuMovedBefore = true;
            oldPosition = componentItem.scrollLeft;
          });

          componentItem.addEventListener("touchmove", (e) => {
            if (!isDown) {
              return;
            }

            e.preventDefault();

            let pageX = (e as TouchEvent).touches[0].pageX;
            let sliderOffset = slider.offsetLeft;
            let x = pageX - sliderOffset;
            let distanceMoved = x - touchPositionX;
            let positionAfterSwipe = scrollLeft - distanceMoved;

            if(wasTheMenuMovedBefore === false){
              componentItem.scrollLeft = positionAfterSwipe;
            }

            else {
              componentItem.scrollLeft = oldPosition + positionAfterSwipe;
            }
          });
        }
      } else if (!isOverflowing) {
        componentItem.classList.add("center");
      }

      if (navMode === true) {
        let navigation = () => {
          let nav = Array.from(
            componentItem.querySelectorAll(".slider-menu-item")
          );

          if(nav){
            nav.forEach((navItem) => {
              let navItemLink = navItem.querySelector(".slider-menu-item-link");
  
              let applyActiveOnMenuClick = () => {
                if (navItemLink) {
                  navItemLink.addEventListener("click", (e) => {
                    e.stopPropagation();
  
                    let target = e.target;
                    let navItemSiblings = getSiblings(navItem as HTMLElement);
  
                    let removeActiveFromSiblings = () => {
                      navItemSiblings.forEach((navItemSibling) => {
                        (navItemSibling as HTMLElement).classList.remove(
                          "active"
                        );
                      });
                    };
  
                    if ((target as HTMLElement).classList.contains("active")) {
                      removeActiveFromSiblings();
                    } else {
                      navItem.classList.add("active");
                      removeActiveFromSiblings();
                    }
                  });
                }
              };
  
              let sectionNavigation = (e: Event, spacingTop: number) => {
                e.preventDefault();
  
                let root = document.documentElement;
                let target = e.target as HTMLElement;
                let targetDataset = target?.dataset.tab;
                let targetSection = document.querySelector(`.history-item[data-tab-item="${targetDataset}"]`);
                let targetSectionPosition = targetSection?.getBoundingClientRect();
                let targetSectionPositionTop = targetSectionPosition?.top;
  
                // "spacingTop" é a altura do menu, que é subtraída do scroll; assim, garantimos que o scroll estará alinhado à seção correspondente.
                if(targetSectionPositionTop){
                  root.scrollTop += targetSectionPositionTop - spacingTop;
                }
              }
  
              applyActiveOnMenuClick();
              navItemLink?.addEventListener('click', (el) => sectionNavigation(el, 100));
            });
          }
        };

        navigation();
      }
    }
  });
};

export default MenuSwipe;
