import "../reset.scss";
import "./styles.scss";

const phoneInput = document.querySelector('[data-js="input"]');

phoneInput?.addEventListener("input", handleInput, false);

function handleInput(e: any) {
  e.target.value = phoneMask(e.target.value);
}

function phoneMask(phone: any) {
  return phone
    .replace(/\D/g, "")
    .replace(/^(\d)/, "($1")
    .replace(/^(\(\d{2})(\d)/, "$1) $2")
    .replace(/(\d{4,5})(\d{4})/, "$1-$2")
    .replace(/(-\d{4})\d+?$/, "$1");
}

const showFormData = () => {
  const formList = document.querySelectorAll(".component-form form.form");

  formList.forEach((formItem: any) => {
    const recaptchaError = formItem?.querySelector(".g-recaptcha-error");

    formItem.addEventListener("submit", (e: any) => {
      e.preventDefault();
      let recaptcha_response =
        formItem?.querySelector("#recaptcha-token").value;

      if (recaptcha_response.length == 0) {
        if (recaptchaError) {
          recaptchaError.innerHTML =
            "<p class='field-error'>Este campo é obrigatório.</p>";
        }
        return false;
      } else {
        new FormData(formItem);
        return true;
      }
    });

    formItem.addEventListener("formdata", (e: any) => {
      const data = e.formData;
      data.delete("g-recaptcha-response");
      const entries = Object.fromEntries(data.entries());
      const jsonData = JSON.stringify(entries);

      const request = new XMLHttpRequest();
      request.addEventListener("readystatechange", () => {
        if (request.readyState === 4) {
          const formParent = formItem.closest(".component-form");
          const loading = formParent.querySelector(".loading");

          if (loading) {
            formParent.removeChild(loading);
          }

          const status = request.status;

          if (status === 400) {
            const response = JSON.parse(request.response);
            const parentDivList = formItem.querySelectorAll(
              ".form-field-wrapper:not(:has(#g-recaptcha))"
            );

            parentDivList.forEach((listItem: HTMLElement) => {
              const field = listItem.lastElementChild?.getAttribute("name");

              if (response[`${field}`]) {
                const node = document.createElement("p");
                node.classList.add("field-error");
                node.innerHTML = response[`${field}`][0];
                listItem.appendChild(node);
              }
            });
          } else if (status === 201) {
            const response = JSON.parse(request.response);

            formParent.innerHTML = `<div class='form-sent-message'>${response.mensagem_sucesso}</div>`;
          }
        } else if (request.readyState < 4) {
          const formParent = formItem.closest(".component-form");
          let loading = formParent.querySelector(".loading");
          if (!loading) {
            loading = document.createElement("div");
            loading.classList.add("loading");
            const loadingSpinner = document.createElement("div");
            loadingSpinner.classList.add("loading-spinner");
            loading.appendChild(loadingSpinner);
            formParent.appendChild(loading);
          }
        }
      });

      request.open("POST", `${formItem.getAttribute("action")}`);
      request.setRequestHeader(
        "Content-Type",
        "application/json;charset=UTF-8"
      );
      request.setRequestHeader("X-CSRFToken", data.get("csrfmiddlewaretoken"));
      request.send(jsonData);
    });
  });
};

const enableButton = () => {
  const formComponents = document.querySelectorAll(
    ".component-form[data-flexible-button=true]"
  );

  formComponents.forEach((formComponentItem) => {
    const form = formComponentItem.querySelector("form");
    const requiredFormFields = form?.querySelectorAll(
      ".form-field-wrapper input[required]:not(input[type=checkbox])"
    );
    const formButton = form?.querySelector(
      ".form-button-wrapper input[type='submit']"
    ) as HTMLButtonElement;
    const formCheck = form?.querySelector(
      ".form-field-wrapper input[required][type=checkbox]"
    ) as HTMLInputElement;

    formButton.disabled = true;
    let validFields: any = [];

    requiredFormFields?.forEach((element: any) => {
      element.addEventListener("change", () => {
        if (element.value) {
          validFields.push(element);
        } else if (validFields.includes(element)) {
          const index = validFields.indexOf(element);
          validFields.splice(index, 1);
        }

        const requiredValidFields = [...new Set(validFields)];

        if (
          requiredValidFields.length === requiredFormFields?.length &&
          formCheck.checked
        ) {
          formButton.disabled = false;
        } else {
          formButton.disabled = true;
        }
      });
    });

    formCheck?.addEventListener("change", () => {
      if (formCheck.checked) {
        const requiredValidFields = [...new Set(validFields)];

        if (
          requiredValidFields.length === requiredFormFields?.length &&
          formCheck.checked
        ) {
          formButton.disabled = false;
        } else {
          formButton.disabled = true;
        }
      }
    });
  });
};

enableButton();
showFormData();
