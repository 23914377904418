import '../reset.scss';
import './styles.scss';
import '../Cards-Publicacoes/styles.scss';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

const sliderNewsSwiper = () => {
  new Swiper('.slider-news .swiper', {
    modules: [Navigation, Pagination],
    spaceBetween: 40,
    pagination: {
      el: '.slider-news .swiper .swiper-pagination',
      clickable: true,
      type: 'bullets',
    },
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev',
    },
    breakpoints: {
      1300: {
        slidesPerView: 3,
        spaceBetween: 40,
      },

      1024: {
        slidesPerView: 2,
        spaceBetween: 40,
      },

      768: {
        slidesPerView: 1,
        spaceBetween: 20,
      },

      425: {
        slidesPerView: 1,
        spaceBetween: 15,
      },

      375: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
    },
  });
}

sliderNewsSwiper();
