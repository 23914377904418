import Swiper from 'swiper'
import { SwiperOptions } from 'swiper/types/swiper-options'
import { CSSSelector } from 'swiper/types/shared'

import '../../components/reset.scss'

import 'swiper/scss'
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'

const slider = (
  selector: string | CSSSelector | HTMLElement,
  config: any | SwiperOptions
) => {
  new Swiper(selector, {
    ...config,
  })
}

export default slider
