import Swiper from "swiper";
import { Navigation, EffectFade } from 'swiper/modules'

import MenuSwipe from "../../modules/MenuSwipe/main";
import Modal from '../../modules/Modal/main';

import "../../components/reset.scss";
import "./styles.scss";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/effect-fade";

const historySwiper = () => {
  let swiperInstances = Array.from(
    document.querySelectorAll(".history-item-content .swiper")
  );

  swiperInstances.forEach((swiperInstance) => {
    let prevBtn = swiperInstance.querySelector(
      ".swiper-button-prev"
    ) as HTMLElement;

    let nextBtn = swiperInstance.querySelector(
      ".swiper-button-next"
    ) as HTMLElement;

    // @ts-ignore
    let swiper = new Swiper(swiperInstance as HTMLElement, {
      modules: [Navigation, EffectFade],
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },

      navigation: {
        prevEl: prevBtn,
        nextEl: nextBtn,
      },

      on: {
        // Eu preciso que a função rode inicialmente, para adicionar o progresso correspondente ao primeiro item.
        init: (param: any) => {
          // console.log("swiper initialized");

          let slider = param.el;
          let singleItemPercentage = 100 / param.slides.length;

          let progressBar = (amount: number) => {
            let progressBarItem = slider.querySelector(
              ".progress-bar"
            ) as HTMLElement;

            if (progressBarItem) {
              let progressBarItemBar = progressBarItem.querySelector(
                ".progress-bar-length-value"
              ) as HTMLElement;

              let amountToIncreaseBar = amount * param.activeIndex;

              if (amountToIncreaseBar <= 0) {
                progressBarItemBar.style.width = amount + "%";
              } else {
                progressBarItemBar.style.width = amountToIncreaseBar + "%";
              }
            }
          };

          progressBar(singleItemPercentage);
        },

        slideNextTransitionStart: (param: any) => {
          // console.log("this has to increase the progress bar");

          let slider = param.el;
          let singleItemPercentage = 100 / param.slides.length;

          let progressBar = (amount: number) => {
            let progressBarItem = slider.querySelector(
              ".progress-bar"
            ) as HTMLElement;

            if (progressBarItem) {
              let progressBarItemBar = progressBarItem.querySelector(
                ".progress-bar-length-value"
              ) as HTMLElement;

              let amountToIncreaseBar = amount * (param.activeIndex + 1);

              if (amountToIncreaseBar <= 0) {
                progressBarItemBar.style.width = amount + "%";
              } else {
                progressBarItemBar.style.width = amountToIncreaseBar + "%";
              }
            }
          };

          progressBar(singleItemPercentage);
        },

        slidePrevTransitionStart: (param: any) => {
          // console.log("this has to decrease the progress bar");

          let slider = param.el;
          let singleItemPercentage = 100 / param.slides.length;

          let progressBar = (amount: number) => {
            let progressBarItem = slider.querySelector(
              ".progress-bar"
            ) as HTMLElement;

            if (progressBarItem) {
              let progressBarItemBar = progressBarItem.querySelector(
                ".progress-bar-length-value"
              ) as HTMLElement;

              let amountToIncreaseBar = amount * (param.activeIndex + 1);

              if (amountToIncreaseBar <= 0) {
                progressBarItemBar.style.width = amount + "%";
              } else {
                progressBarItemBar.style.width = amountToIncreaseBar + "%";
              }
            }
          };

          progressBar(singleItemPercentage);
        },
      },
    });

    // swiper.on("slideChange", () => {
    //   console.log("slide changed");
    // });
  });
};

historySwiper();
Modal("history");
MenuSwipe(".component-slider-menu", ".slider-menu", true);

window.addEventListener("resize", () => {
  MenuSwipe(".component-slider-menu", ".slider-menu", true);
});
