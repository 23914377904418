import "../reset.scss";
import "./styles.scss";
import Choices from "choices.js";

const changeURL = () => {
  const comboList = document.querySelectorAll('.selection-panel-content');

  comboList.forEach((element) => {
    const select: any = element.querySelector('.select');

    select.addEventListener('change', () => {
      window.location.href = select.value
    })
  })
}

const emTempoRealCustomSelect = function () {
  let elements = Array.from(document.querySelectorAll(".select"));

  if (elements) {
    elements.forEach(function (item) {
      let choices = () =>
        new Choices(item, {
          searchEnabled: false,
          itemSelectText: "",
          position: "bottom",
        });

      choices();
    });
  }
};

changeURL();
emTempoRealCustomSelect();
