import '../reset.scss';
import './styles.scss';
import 'swiper/css';
import 'swiper/scss/pagination';
import 'swiper/scss/effect-fade';

import Swiper from 'swiper';
import { Pagination, EffectFade } from 'swiper/modules';
import Modal from '../../modules/Modal/main';

const webdoorSwiper = () => {
  new Swiper('.webdoor .swiper', {
    modules: [Pagination, EffectFade],
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },

    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
  });
}

webdoorSwiper();
Modal("webdoor");
