import '../base.scss'
import '../reset.scss'
import './styles.scss'

import { Navigation } from 'swiper/modules'

import slider from '../../modules/Slider/main'

const multimediaGalleryConfig = {
  modules: [Navigation],
  spaceBetween: 0,
  loop: true,
  slidesPerView: 1,
  navigation: {
    nextEl: '.swiper-next',
    prevEl: '.swiper-prev',
  },
}

slider('.gallery .swiper', multimediaGalleryConfig)
