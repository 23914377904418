import "../reset.scss";
import "./styles.scss";

const externalLink = () => {
  const main = document.querySelector("main") as HTMLElement;
  const value = Array.from(main.querySelectorAll("a"));

  for (let i = 0; i < value.length; i++) {
    const valueItem = value[i];
    const links = valueItem.getAttribute("href");

    if (links?.includes("http" || "https")) {
      valueItem.setAttribute("target", "_blank");
    }
  }
};

externalLink();
