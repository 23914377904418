import Modal from "../../modules/Modal/main";
import { removeScrollFromBody, previousSiblings } from "../../helpers/main";

import '../reset.scss'
import './styles.scss'

let Header = () => {
  let header = document.querySelector('#header') as HTMLElement; // inferência de tipos garante (para o código) que não haverá valor null!
  
  if (header){
    let menuMobileBtn = header.querySelector('.hamburger') as HTMLButtonElement;
    let isMobile = window.matchMedia('(max-width: 1023px)').matches;

    if(isMobile){
      let isOpen = false;
  
      let menuMobile = () => {
        let menuHandler = (e: Event) => {
          e.preventDefault();

          let eventTarget = e.target as HTMLElement;
          isOpen = !isOpen;
  
          if(isOpen === true){
            header.classList.add('active');
            eventTarget.classList.add('is-active');
            removeScrollFromBody(isOpen);
          }
  
          else {
            header.classList.remove('active');
            eventTarget.classList.remove('is-active');
            removeScrollFromBody(isOpen);
          }
        };
  
        let topListHandler = () => {
          if(isOpen === false) {
            let headerBottomWrapper = document.querySelector('.header-bottom-wrapper') as HTMLElement;
            let topList = header.querySelector('.header-top-list') as HTMLElement;
            /*Essa linha de código está fazendo com que os itens do menu fiquem se repetindo no responsivo
             *let topListClone = topList.cloneNode(true); 
             */

            /**
             * Inserir o topList ao interior do headerBottomWrapper quando isApended for false;
             * Quando isApender é true, remover o topList próximo ao dropdown.
             */
            let appendCloneToMenu = () => {
              let isApended = false;

              if(isApended === false){
                headerBottomWrapper?.appendChild(topList);
                isApended = !isApended;
              }
            }

            appendCloneToMenu();
          }
        }
  
        let dropdownHandler = () => {
          let dropdownItensBtn = Array.from(document.querySelectorAll('.header-bottom-item-link'));
          let dropdownSearch = header.querySelector('.header-bottom-item.search') as HTMLElement;
          let headerTopInsideBottomList = document.querySelector('.header-bottom-wrapper .header-top-list') as HTMLElement;
          let isDropdownOpen = false;

          dropdownItensBtn.forEach((dropdownItemBtn) => {
            dropdownItemBtn.addEventListener('click', (el) => {
              let dropdown = dropdownItemBtn.nextElementSibling as HTMLElement;
          
              if(dropdown){
                el.preventDefault();
          
                let dropdownPrev = previousSiblings(dropdownItemBtn.parentElement); 
                isDropdownOpen = !isDropdownOpen;
          
                if(isDropdownOpen === true){
                  dropdown.classList.add('show');
                  dropdownSearch.classList.add('hide');
                  headerTopInsideBottomList.classList.add('hidden');
                  dropdownItemBtn.classList.add('dropdown-opened');
          
                  dropdownPrev.forEach((element) => {
                    if(element != dropdownPrev[dropdownPrev.length-1]){
                      element.classList.add('hidden');
                    }
                  })
                }
          
                else {
                  dropdown.classList.remove('show');
                  dropdownSearch.classList.remove('hide');
                  headerTopInsideBottomList.classList.remove('hidden');
                  dropdownItemBtn.classList.remove('dropdown-opened');
          
                  dropdownPrev.forEach((element) => {
                    if(element != dropdownPrev[dropdownPrev.length-1]){
                      element.classList.remove('hidden');
                    }
                  })
                }
              }
            });
          });
        }
  
        menuMobileBtn.addEventListener('click', (e) => menuHandler(e));
        topListHandler();
        dropdownHandler();
      }
  
      menuMobile();
    }

    if(!isMobile){
      let topListHandler = () => {
        let headerTop = document.querySelector('.header-top') as HTMLElement;
        let headerBottomWrapper = document.querySelector('.header-bottom-wrapper') as HTMLElement;
        let topList = header.querySelector('.header-top-list') as HTMLElement;
        /*Essa linha de código está fazendo com que os itens do menu fiquem se repetindo no responsivo
          *let topListClone = topList.cloneNode(true); 
          */
        
        let topListIsInHeaderBottom: boolean; 

        headerBottomWrapper?.childNodes.forEach((element: any) => {
          if(element?.className === 'header-top-list') {
            topListIsInHeaderBottom = true;
          }
        })

        
        let removeCloneToMenu = () => {
          let isRemoved = false
          
          if(topListIsInHeaderBottom === true && isRemoved === false){
            headerBottomWrapper?.removeChild(topList);
            isRemoved = !isRemoved;
          }
        }

        let appendCloneToOriginalMenu = () => {
          let isApended = false

          if(isApended === false){
            headerTop?.appendChild(topList);
            isApended = !isApended;
          }
        }

        removeCloneToMenu();
        appendCloneToOriginalMenu();
      }

      topListHandler();
    }
  }
}

window.addEventListener('resize', Header);

Header();
Modal("header");
