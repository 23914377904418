let getSiblings = (elem: HTMLElement) => {
  let siblings = [];
  let sibling = elem.parentNode?.firstChild;

  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== elem) {
      siblings.push(sibling);
    }

    sibling = sibling.nextSibling;
  }

  return siblings;
};

const previousSiblings = (elem:any) => {
  let siblings = [];

  while ((elem = elem.previousElementSibling)) {
    siblings.push(elem);
  }

  return siblings;
};

let removeScrollFromBody = (conditionValue: boolean) => {
  let body = document.querySelector("body");

  if (conditionValue) {
    body?.classList.add("no-scroll");
  } else {
    body?.classList.remove("no-scroll");
  }
};

// Retorna 'true', se o elemento está visível no viewport ou 'false', caso contrário.
let isInViewport = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

type isOverflownTypes = {
  clientWidth: number;
  clientHeight: number;
  scrollWidth: number;
  scrollHeight: number;
};

// Retorna um boolean, se o elemento está vazando do pai.
const isOverflown = (element: isOverflownTypes) => {
  if (element) {
    return (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    );
  }
};

export {
  getSiblings,
  previousSiblings,
  removeScrollFromBody,
  isInViewport,
  isOverflown
};
