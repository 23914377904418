import "../reset.scss";
import "../../modules/MenuSwipe/main.ts"
import MenuSwipe from "../../modules/MenuSwipe/main";

MenuSwipe(".component-slider-menu", ".slider-menu");

const changeURL = () => {
  const comboList = document.querySelectorAll('.component-slider-container.var-d');

  comboList.forEach((element) => {
    const select: any = element.querySelector('.select');

    select.addEventListener('change', () => {
      window.location.href = select.value
    })
  })
}

changeURL();

window.addEventListener("resize", () => {
  MenuSwipe(".component-slider-menu", ".slider-menu");
});
